import axios from "axios";
import { useEffect, useState } from "react";
import APIConfig from "../Configuration/APIConfig";
import { Link } from "react-router-dom";

export default function PopularDestination() {
    const [holidayDestinations, setHolidayDestinations] = useState([]);
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken"),
        }
    }
    const GetHolidayDestinations = () => {
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETHOLIDAYDESTINATIONS, { ...headerconfig })
            .then((response) => {
                if (response.data.data !== null) {
                    setHolidayDestinations(response.data.data.data);
                }
            });
    };
    useEffect(() => {
        GetHolidayDestinations();
    }, [])
    return (
        <section>
            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className="col-xl-8 col-lg-9 col-md-11 col-sm-12">
                        <div className="secHeading-wrap text-center mb-5">
                            <h2>Explore Popular Destination</h2>
                            <p>Discover the World's Favorites: Explore Popular Destinations!"</p>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center gy-4 gx-3">
                    {holidayDestinations.length > 0 && holidayDestinations.map(hd =>
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                            <div className="destination-blocks bg-white p-2 rounded border br-dashed h-100">
                                <div className="destination-blocks-pics p-1">
                                    <Link to={"/tours"}><img src={hd.imageSrc} className="img-fluid rounded" alt={hd.destinationName} /></Link>
                                </div>
                                <div className="destination-blocks-captions">
                                    <div className="touritem-flexxer text-center p-3">
                                        <h4 className="city fs-5 m-0 fw-bold">
                                            <span>{hd.destinationName}</span>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </section>
    )
}