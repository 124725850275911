import { useEffect, useState } from "react";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import APIConfig from "../Configuration/APIConfig";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import PageLoader from "../Layout/PageLoader";
import moment from "moment";
import Modal from 'react-bootstrap/Modal';
const visaRequestFieldValues = {
    visaRequestId: "00000000-0000-0000-0000-000000000000",
    visaId: "00000000-0000-0000-0000-000000000000",
    leadSource: "ONLINE",
    visaName: "",
    countryName: "",
    pricePerApplicant: 0,
    status: "NEW",
    travelDate: new Date(),
    noOfApplicants: 0,
    totalPrice: 0,
    userId: "00000000-0000-0000-0000-000000000000"
};
export default function Visas() {
    const { state } = useLocation();
    const [values, setValues] = useState(visaRequestFieldValues);
    const params = useParams();
    const navigate = useNavigate();
    const [visaCountry, setVisaCountry] = useState([]);
    const [visas, setVisas] = useState([]);
    const [visaDocuments, setVisaDocuments] = useState([]);
    const [visaTerms, setVisaTerms] = useState([]);
    const [visaFAQS, setVisaFAQS] = useState([]);
    const [email, setEmail] = useState();
    const [mobile, setMobile] = useState();
    const [noOfApplicants, setNoOfApplicants] = useState(1);
    const [travelDate, setTravelDate] = useState(new Date());
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState({});
    const [loginShow, setLoginShow] = useState(false);
    const handleLoginClose = () => setLoginShow(false);
    const handleLoginShow = () => { setLoginShow(true); }
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken"),
        }
    }
    const CheckUser = () => {
        if (localStorage.getItem('userId') !== null) {
            return true;
        }
        else {
            return false;
        }
    }
    const validate = () => {
        let temp = {};
        temp.email = email == '' || email == undefined ? false : true;
        temp.password = mobile == '' || mobile == undefined ? false : true;
        setErrors(temp);
        return Object.values(temp).every((x) => x == true);
    };
    const CreateVisaRequest=(c)=>{
         const formData = new FormData()
         formData.append("visaRequestId", values.visaRequestId)
         formData.append("visaId", c.visaId)
         formData.append("leadSource", values.leadSource)
         formData.append("visaName", c.visaName)
         formData.append("countryName", visaCountry.name)
         formData.append("pricePerApplicant", c.salePrice)
         formData.append("netPrice", parseFloat(c.salePrice * noOfApplicants))
         formData.append("gst", 0)
         formData.append("totalPrice", parseFloat(c.salePrice * noOfApplicants))
         formData.append("visaRequestStatus", values.status)
         formData.append("travelDate", moment(travelDate).format('YYYY-MM-DD'))
         formData.append("noOfApplicants", noOfApplicants)
         formData.append("userId", values.userId)
         formData.append("email", email)
         formData.append("mobile", mobile)
         formData.append("visaRequestStatus", "NEW")
         axios.post(APIConfig.APIACTIVATEURL + APIConfig.CREATEVISAREQUEST, formData, { ...headerconfig })
             .then((res) => {
                 navigate('/visa/' + res.data.data + "/" + params.noOfApplicants + "/" + params.travelDate);
        })
    }
    const handleSubmit = (e, c) => {
        e.preventDefault();
        if (!CheckUser()) {
            setLoginShow(true)
            if (validate()) {
                setLoginShow(false)
                CreateVisaRequest(c);
            }
        }
        else {
            CreateVisaRequest(c);
        }
    };
    const handleOkSubmit = (e, c) => {
        e.preventDefault();
        if (!CheckUser()) {
            setLoginShow(true)
            if (validate()) {
                setLoginShow(false)
            }
        }
        else {
            CreateVisaRequest(c);
        }
    };
    const GetUserData = () => {
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETUSERBYID + "?Id=" + localStorage.getItem('userId'), { ...headerconfig })
            .then((response) => {
                if (response.data.response.succeeded) {
                    setEmail(response.data.response.data.email)
                    setMobile(response.data.response.data.phoneNumber)
                }
            });
    };
    const GetVisas = () => {
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETVISAPRICEBYCOUNTRY + "?Url=" + params.visaCountry, { ...headerconfig })
            .then((response) => {
                setVisaCountry(response.data.data);
                setVisas(response.data.data.visas);
                setVisaDocuments(response.data.data.visaDocuments);
                setVisaTerms(response.data.data.visaTerms);
                setVisaFAQS(response.data.data.visaFAQs);
                if (params.noOfApplicants !== undefined) {
                    setNoOfApplicants(params.noOfApplicants)
                    setTravelDate(params.travelDate)
                }
                setLoading(false)
            });
    };
    const applyErrorClass = (field) =>
        field in errors && errors[field] == false ? ' form-control-danger' : '';
    useEffect(() => {
        GetVisas();
        if (localStorage.getItem('userId') !== null) {
            GetUserData();
        }
    }, []);
    return (
        <div id="main-wrapper">
            {loading === true ? <PageLoader></PageLoader> :
                <div>
                    <Header></Header>
                    <div className="clearfix"></div>
                    <div className="py-2 bg-primary position-relative">
                    </div>
                    <section className="gray-simple">
                        <div className="container">
                            <div className="row justify-content-between gy-4 gx-xl-4 gx-lg-3 gx-md-3 gx-4">
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-xl-4 col-lg-4 col-md-4">
                                            <h5 className="fw-bold fs-6 mb-lg-0 mb-3">Showing {params.visaCountry} Visas</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <div className="row justify-content-center gy-4 gx-xl-4 gx-3">
                                        {visas.length > 0 && visas.map(v =>
                                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                                <div className="pop-touritem">
                                                    <a href="#" className="card rounded-3 shadow-wrap m-0">
                                                        <div className="touritem-middle position-relative p-3">
                                                            <div className="touritem-flexxer">
                                                                <h4 className="city fs-4 m-0 fw-bold">
                                                                    <span>{v.visaName}</span>
                                                                </h4>
                                                                <p className="detail ellipsis-container">
                                                                    <span className="ellipsis-item__normal">{v.name}</span>
                                                                    <span className="separate ellipsis-item__normal" />
                                                                    <span className="ellipsis-item">{v.entry}</span>
                                                                    <span className="separate ellipsis-item__normal" />
                                                                    <span className="ellipsis-item">{v.visaType} Visa</span>
                                                                </p>
                                                                <div className="touritem-centrio mt-4">
                                                                    <div className="aments-lists mt-2">
                                                                        <div className="detail ellipsis-container mt-1">
                                                                            <span className="ellipsis">{v.processingTime} Day(s) Processing Time</span>
                                                                            <span className="ellipsis">{v.validity} Validity</span>
                                                                            <span className="ellipsis">{v.stayPeriod} Stay Period</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="trsms-foots mt-4">
                                                                <div className="flts-flex d-flex align-items-end justify-content-between">
                                                                    <div className="flts-flex-strat">
                                                                        <div className="d-flex align-items-center">
                                                                            <div className="text-dark fw-bold fs-4">INR {v.salePrice}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="flts-flex-end">
                                                                        <div className="row align-items-center justify-content-end gx-2">
                                                                            <div className="col-auto">
                                                                                <button onClick={e => handleSubmit(e, v)} className="btn btn-md btn-primary full-width fw-medium px-lg-4">Apply Now</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Modal show={loginShow} onHide={handleLoginClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Please enter email and mobile</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="modal-login-form py-4 px-md-3 px-0">
                                <form onSubmit={handleOkSubmit} autoComplete="off" noValidate>
                                    <div className="form-floating mb-4">
                                        <input className={'form-control' + applyErrorClass('email')} name="email" type="text" value={email} onChange={e => setEmail(e.target.value)} placeholder="Enter Email" />
                                        {errors.email === false ? (<div className="validationerror"> Please enter email </div>) : ('')}
                                        <label>Email</label>
                                    </div>
                                    <div className="form-floating mb-4">
                                        <input className={'form-control' + applyErrorClass('mobile')} name="mobile" type="text" value={mobile} onChange={e => setMobile(e.target.value)} placeholder="Enter Mobile" />
                                        {errors.password === false ? (<div className="validationerror"> Please enter mobile </div>) : ('')}
                                        <label>Mobile</label>
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-primary full-width font--bold btn-lg">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </Modal.Body>
                    </Modal>
                    <Footer></Footer>
                </div>
            }
        </div>
    )
}