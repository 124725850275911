import { Routes, Route } from 'react-router-dom';
import SearchFlight from './Flights/SearchFlight';
import SearchTour from './Tours/SearchTour';
import SearchVisa from './Visas/SearchVisa';
import AboutUs from './Home/AboutUs';
import OneWay from './Flights/OneWay';
import OneWayFlight from './Flights/OneWayFlight';
import OneWayItinerary from './Flights/OneWayItinerary';
import RoundTrip from './Flights/RoundTrip';
import RoundTripFlight from './Flights/RoundTripFlight';
import RoundTripItinerary from './Flights/RoundTripItinerary';
import PrivacyPolicy from './Home/PrivacyPolicy';
import Terms from './Home/Terms';
import FAQ from './Home/FAQ';
import Contact from './Home/Contact';
import Profile from './User/Profile';
import MyBookings from './User/MyBookings';
import MyTravellers from './User/MyTravellers';
import Logout from './Authentication/Logout';
import FlightBookings from './User/FlightBookings';
import FlightBookingDetails from './User/FlightBookingDetails';
import ForgetPassword from './User/ForgetPassword';
import ResetPassword from './User/ResetPassword';
import Refund from './Home/Refund';
import OneWayReview from './Flights/OneWayReview';
import RoundTripReview from './Flights/RoundTripReview';
import Visas from './Visas/Visas';
import VisaApplicant from './Visas/VisaApplicant';
import VisaSubmission from './Visas/VisaSubmission';
import Tour from './Tours/Tour';
import TourDetails from './Tours/TourDetails';
function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<SearchFlight />} />
        <Route path="/searchflights" element={<SearchFlight />} />
        <Route path="/oneway-flights/:adults?/:kids?/:infants?/:isDomestic?" element={<OneWay />} />
        <Route path="/oneway-flight/:adults?/:kids?/:infants??/:isDomestic?" element={<OneWayFlight />} />
        <Route path="/oneway-review/:adults?/:kids?/:infants??/:isDomestic?" element={<OneWayReview />} />
        <Route path="/oneway-itinerary" element={<OneWayItinerary />} />

        <Route path="/roundtrip-flights/:adults?/:kids?/:infants?/:isDomestic?" element={<RoundTrip />} />
        <Route path="/roundtrip-flight/:adults?/:kids?/:infants?/:isDomestic?" element={<RoundTripFlight />} />
        <Route path="/roundtrip-review/:adults?/:kids?/:infants?/:isDomestic?" element={<RoundTripReview />} />
        <Route path="/roundtrip-itinerary" element={<RoundTripItinerary />} />

        <Route path="/searchvisas" element={<SearchVisa />} />
        <Route path="/visas/:visaCountry?/:noOfApplicants?/:travelDate?" element={<Visas />} />
        <Route path="/visa/:visaRequestId?/:noOfApplicants?/:travelDate?" element={<VisaApplicant />} />
        <Route path="/visasubmission" element={<VisaSubmission />} />

        <Route path="/searchtours" element={<SearchTour />} />
        <Route path="/tours" element={<Tour />} />
        <Route path="/tourdetails/:tourId?" element={<TourDetails />} />

        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/refund" element={<Refund />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/resetpassword" element={<ResetPassword />} />     
        <Route path="/logout" element={<Logout />} />
        

        <Route path="/profile" element={<Profile />} />
        <Route path="/mybookings" element={<MyBookings />} />
        <Route path="/flightbookings" element={<FlightBookings />} />
        <Route path="/flight-bookings-details/:flightBookingId" element={<FlightBookingDetails />} />
        <Route path="/mytravellers" element={<MyTravellers />} />
      </Routes>
    </div>
  );
}

export default App;
