import axios from "axios";
import { useEffect, useState } from "react";
import APIConfig from "../Configuration/APIConfig";

export default function Currency() {
    return(
        <div className="modal modal-lg fade" id="currencyModal" tabIndex={-1} aria-labelledby="currenyModalLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h4 className="modal-title fs-6" id="currenyModalLabel">Select Your Currency</h4>
        <a href="#" className="text-muted fs-4" data-bs-dismiss="modal" aria-label="Close"><i className="fa-solid fa-square-xmark" /></a>
      </div>
      <div className="modal-body">
        <div className="allCurrencylist">
          <div className="suggestedCurrencylist-wrap mb-4">
            <div className="d-inline-block mb-0 ps-3">
              <h5 className="fs-6 fw-bold">Suggested Currency For you</h5>
            </div>
            <div className="suggestedCurrencylists">
              <ul className="row align-items-center justify-content-start row-cols-xl-4 row-cols-lg-3 row-cols-md-2 row-cols-2 gy-2 gx-3 m-0 p-0">
                <li className="col">
                  <a className="selectCurrency" href="#">
                    <div className="text-dark text-md fw-medium">United State Dollar</div>
                    <div className="text-muted-2 text-md text-uppercase">USD</div>
                  </a>
                </li>
                <li className="col">
                  <a className="selectCurrency" href="#">
                    <div className="text-dark text-md fw-medium">Pound Sterling</div>
                    <div className="text-muted-2 text-md text-uppercase">GBP</div>
                  </a>
                </li>
                <li className="col">
                  <a className="selectCurrency active" href="#">
                    <div className="text-dark text-md fw-medium">Indian Rupees</div>
                    <div className="text-muted-2 text-md text-uppercase">Inr</div>
                  </a>
                </li>
                <li className="col">
                  <a className="selectCurrency" href="#">
                    <div className="text-dark text-md fw-medium">Euro</div>
                    <div className="text-muted-2 text-md text-uppercase">EUR</div>
                  </a>
                </li>
                <li className="col">
                  <a className="selectCurrency" href="#">
                    <div className="text-dark text-md fw-medium">Australian Dollar</div>
                    <div className="text-muted-2 text-md text-uppercase">aud</div>
                  </a>
                </li>
                <li className="col">
                  <a className="selectCurrency" href="#">
                    <div className="text-dark text-md fw-medium">Thai Baht</div>
                    <div className="text-muted-2 text-md text-uppercase">thb</div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="suggestedCurrencylist-wrap">
            <div className="d-inline-block mb-0 ps-3">
              <h5 className="fs-6 fw-bold">All Currencies</h5>
            </div>
            <div className="suggestedCurrencylists">
              <ul className="row align-items-center justify-content-start row-cols-xl-4 row-cols-lg-3 row-cols-md-2 row-cols-2 gy-2 gx-3 m-0 p-0">
                <li className="col">
                  <a className="selectCurrency" href="#">
                    <div className="text-dark text-md fw-medium">United State Dollar</div>
                    <div className="text-muted-2 text-md text-uppercase">USD</div>
                  </a>
                </li>
                <li className="col">
                  <a className="selectCurrency" href="#">
                    <div className="text-dark text-md fw-medium">Property currency</div>
                    <div className="text-muted-2 text-md text-uppercase">GBP</div>
                  </a>
                </li>
                <li className="col">
                  <a className="selectCurrency" href="#">
                    <div className="text-dark text-md fw-medium">Argentine Peso</div>
                    <div className="text-muted-2 text-md text-uppercase">EUR</div>
                  </a>
                </li>
                <li className="col">
                  <a className="selectCurrency" href="#">
                    <div className="text-dark text-md fw-medium">Azerbaijani Manat</div>
                    <div className="text-muted-2 text-md text-uppercase">Inr</div>
                  </a>
                </li>
                <li className="col">
                  <a className="selectCurrency" href="#">
                    <div className="text-dark text-md fw-medium">Australian Dollar</div>
                    <div className="text-muted-2 text-md text-uppercase">aud</div>
                  </a>
                </li>
                <li className="col">
                  <a className="selectCurrency" href="#">
                    <div className="text-dark text-md fw-medium">Bahraini Dinar</div>
                    <div className="text-muted-2 text-md text-uppercase">thb</div>
                  </a>
                </li>
                <li className="col">
                  <a className="selectCurrency" href="#">
                    <div className="text-dark text-md fw-medium">Brazilian Real</div>
                    <div className="text-muted-2 text-md text-uppercase">USD</div>
                  </a>
                </li>
                <li className="col">
                  <a className="selectCurrency" href="#">
                    <div className="text-dark text-md fw-medium">Bulgarian Lev</div>
                    <div className="text-muted-2 text-md text-uppercase">GBP</div>
                  </a>
                </li>
                <li className="col">
                  <a className="selectCurrency" href="#">
                    <div className="text-dark text-md fw-medium">Canadian Dollar</div>
                    <div className="text-muted-2 text-md text-uppercase">EUR</div>
                  </a>
                </li>
                <li className="col">
                  <a className="selectCurrency" href="#">
                    <div className="text-dark text-md fw-medium">Chilean Peso</div>
                    <div className="text-muted-2 text-md text-uppercase">Inr</div>
                  </a>
                </li>
                <li className="col">
                  <a className="selectCurrency" href="#">
                    <div className="text-dark text-md fw-medium">Colombian Peso</div>
                    <div className="text-muted-2 text-md text-uppercase">aud</div>
                  </a>
                </li>
                <li className="col">
                  <a className="selectCurrency" href="#">
                    <div className="text-dark text-md fw-medium">Danish Krone</div>
                    <div className="text-muted-2 text-md text-uppercase">thb</div>
                  </a>
                </li>
                <li className="col">
                  <a className="selectCurrency" href="#">
                    <div className="text-dark text-md fw-medium">Egyptian Pound</div>
                    <div className="text-muted-2 text-md text-uppercase">USD</div>
                  </a>
                </li>
                <li className="col">
                  <a className="selectCurrency" href="#">
                    <div className="text-dark text-md fw-medium">Hungarian Forint</div>
                    <div className="text-muted-2 text-md text-uppercase">GBP</div>
                  </a>
                </li>
                <li className="col">
                  <a className="selectCurrency" href="#">
                    <div className="text-dark text-md fw-medium">Japanese Yen</div>
                    <div className="text-muted-2 text-md text-uppercase">EUR</div>
                  </a>
                </li>
                <li className="col">
                  <a className="selectCurrency" href="#">
                    <div className="text-dark text-md fw-medium">Jordanian Dinar</div>
                    <div className="text-muted-2 text-md text-uppercase">Inr</div>
                  </a>
                </li>
                <li className="col">
                  <a className="selectCurrency" href="#">
                    <div className="text-dark text-md fw-medium">Kuwaiti Dinar</div>
                    <div className="text-muted-2 text-md text-uppercase">aud</div>
                  </a>
                </li>
                <li className="col">
                  <a className="selectCurrency" href="#">
                    <div className="text-dark text-md fw-medium">Malaysian Ringgit</div>
                    <div className="text-muted-2 text-md text-uppercase">thb</div>
                  </a>
                </li>
                <li className="col">
                  <a className="selectCurrency" href="#">
                    <div className="text-dark text-md fw-medium">Singapore Dollar</div>
                    <div className="text-muted-2 text-md text-uppercase">thb</div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


    )
}