import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import { useEffect, useState, useRef } from "react";
import APIConfig from "../Configuration/APIConfig";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import PageLoader from "../Layout/PageLoader";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useReactToPrint } from 'react-to-print';
export default function RoundTripItinerary() {
    const { state } = useLocation();
    const navigate = useNavigate();
    const [flightData, setFlightData] = useState([])
    const [segments, setSegments] = useState([])
    const [returnSegments, setReturnSegments] = useState([])
    const [travellers, setTravellers] = useState([])
    const [baseprice, setBasePrice] = useState(0)
    const [tax, setTax] = useState(0)
    const [totalPrice, setTotalPrice] = useState(0)
    const [bookingStatus, setBookingStatus] = useState('')
    const [confirmationNo, setConfirmationNo] = useState('')
    const [pnr, setPNR] = useState('')
    const [email, setEmail] = useState('')
    const contentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => contentRef.current,
    });
    const downloadPDF = () => {
        const input = contentRef.current;

        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF();
            const imgWidth = 210;
            const pageHeight = 297;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;
            let position = 0;

            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }

            pdf.save('flightbooking.pdf');
        });
    };
    function getTimeFromMins(mins) {
        var h = mins / 60 | 0, m = mins % 60 | 0;
        var durationformat = h + "h " + m + "m";
        return durationformat;
    }
    useEffect(() => {
        console.log(state);
        if (state === null) {
            //navigate('/searchflights')
        }
        else {
            if (state.flightBookingRequest == undefined) {
                //navigate('/searchflights')
            }
            else if (state.flightBookingRequest.tfSegments == undefined) {
                //navigate('/searchflights')
            }
            else {
                setFlightData(state.flightBookingRequest)
                if (state.flightBookingRequest != null) {
                    if (state.flightBookingRequest != null) {
                        if(state.flightBookingRequest.deliveryInfo != null){
                            setEmail(state.flightBookingRequest.deliveryInfo.emails[0])
                        }
                    }
                    setSegments(state.flightBookingRequest.tfSegments)
                    if (state.flightBookingRequest.travellerInfos) {
                        setTravellers(state.flightBookingRequest.travellerInfos)
                    }
                    if (state.flightBookingRequest != null) {
                        setBasePrice(state.flightBookingRequest.basePrice)
                        setTax(state.flightBookingRequest.tax)
                        setTotalPrice(state.flightBookingRequest.totalPrice)
                    }
                    //setReturnSegments(state.returnData.tFSegments)
                    if (state.flightBookingRequest != null) {
                        setConfirmationNo(state.flightBookingRequest.bookingNo)
                        setBookingStatus(state.flightBookingRequest.bookingStatus)
                    }
                }
            }
        }
    }, []);
    return (
        <div id="main-wrapper">
            <Header></Header>
            <div className="clearfix"></div>
            <div className="pt-3 gray-simple">
                <div className="container">
                    <div className="row">
                        {/* Breadcrumb */}
                        <div className="col-xl-12 col-lg-12 col-md-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="#" className="text-primary">Home</a></li>
                                    <li className="breadcrumb-item"><a href="#" className="text-primary">Flight</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Booking</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div className="gray-simple position-relative">
                <div className="container">
                    <div className="row align-items-start">
                        <div className="col-xl-12 col-lg-12 col-md-12">
                            <div className="card mb-3">
                                <div className="car-body px-xl-5 px-lg-4 py-lg-5 py-4 px-2">
                                    <div className="d-flex align-items-center justify-content-center mb-3">                                   </div>
                                    <div ref={contentRef}>
                                    <div className="text-center d-flex align-items-center justify-content-center">
                                        <img src="/assets/img/logo-light.png" width={200} height={100}></img>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-center flex-column text-center mb-5 mt-2">
                                        <h3 className="mb-0">Booking Confirmation!</h3>
                                    </div>
                                    <p style={{ paddingLeft: "20px" }}>Dear Traveler, Following are the flight booking details</p>
                                    <div className="d-flex align-items-center justify-content-center flex-column mb-4">
                                        <hr className="mb-4" />
                                        {segments.length > 0 && segments.map((seg, index) =>
                                            <div className="card mb-4">
                                                <div className="card-header">
                                                    <div className="row align-items-center trip-title">
                                                        <div className="col-5 col-sm-auto text-center text-sm-start">
                                                            <h5 className="m-0 trip-place">{seg.tFDepartureData.city}, {seg.tFDepartureData.country}</h5>
                                                        </div>
                                                        <div className="col-2 col-sm-auto text-8 text-black-50 text-center trip-arrow">➝</div>
                                                        <div className="col-5 col-sm-auto text-center text-sm-start">
                                                            <h5 className="m-0 trip-place">{seg.tFArrivalData.city}, {seg.tFArrivalData.country}</h5>
                                                        </div>
                                                        <div className="col-12 mt-1 d-block d-md-none" />
                                                        <div className="col-6 col-sm col-md-auto text-3 date">{moment(seg.tFDepartureData.departureDateTime).format('Do MMM YYYY ddd')}</div>
                                                        <div className="col col-md-auto text-center ms-auto order-sm-0">{bookingStatus === "CONFIRMED" ? <span className="badge bg-success py-1 px-2 fw-normal text-0">Confirmed <i className="fas fa-check-circle" /></span> : <span className="badge bg-danger py-1 px-2 fw-normal text-0">FAILED <i className="fas fa-check-circle" /></span>}</div>
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-12 col-sm-3 text-center text-md-start d-lg-flex company-info"> <span className="align-middle"><img className="img-fluid" alt src={"/assets/images/airlinelogos/" + seg.airlineCode + ".png"} /> </span> <span className="align-middle ms-lg-2"> <span className="d-block text-2 text-dark mt-1 mt-lg-0">{seg.airline}</span> <small className="text-muted d-block">{seg.equimentType + "-" + seg.flightNumber}</small> </span> </div>
                                                        <div className="col-12 col-sm-3 text-center time-info mt-3 mt-sm-0"> <span className="text-5 text-dark">{moment(seg.tFDepartureData.departureDateTime).format('hh:mm A')}</span> <small className="text-muted d-block">Departure</small> </div>
                                                        <div className="col-12 col-sm-3 text-center time-info mt-3 mt-sm-0"> <span className="text-3 text-dark">{getTimeFromMins(seg.duration)}</span> <small className="text-muted d-block">Duration</small> </div>
                                                        <div className="col-12 col-sm-3 text-center time-info mt-3 mt-sm-0"> <span className="text-5 text-dark">{moment(seg.tFArrivalData.arrivalDateTime).format('hh:mm A')}</span> <small className="text-muted d-block">Arrival</small> </div>
                                                    </div>
                                                    <p className="mt-3 lh-base text-1">Booking ID: {confirmationNo}<br />
                                                        Class of Service: Economy</p>
                                                    <div className="row">
                                                        <div className="col-4 text-1 lh-base"> <strong className="text-uppercase text-2 fw-600">Airport Info</strong><br />
                                                            {seg.tFDepartureData.airportName},<br />
                                                            {seg.city}<br />
                                                        </div>
                                                        <div className="col-4 text-1 lh-base"> <strong className="text-uppercase text-2 fw-600">Flight Info</strong><br />
                                                            {seg.airline} {seg.equimentType + "-" + seg.flightNumber}<br />
                                                            Breakfast, Meal
                                                        </div>
                                                        <div className="col-4 text-1 lh-base"> <strong className="text-uppercase text-2 fw-600">Airport Info</strong><br />
                                                            {seg.tFArrivalData.airportName},<br />
                                                            {seg.tFArrivalData.city}<br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {returnSegments.length > 0 && returnSegments.map((seg, index) =>
                                            <div className="card mb-4">
                                                <div className="card-header">
                                                    <div className="row align-items-center trip-title">
                                                        <div className="col-5 col-sm-auto text-center text-sm-start">
                                                            <h5 className="m-0 trip-place">{seg.tFDepartureData.city}, {seg.tFDepartureData.country}</h5>
                                                        </div>
                                                        <div className="col-2 col-sm-auto text-8 text-black-50 text-center trip-arrow">➝</div>
                                                        <div className="col-5 col-sm-auto text-center text-sm-start">
                                                            <h5 className="m-0 trip-place">{seg.tFArrivalData.city}, {seg.tFArrivalData.country}</h5>
                                                        </div>
                                                        <div className="col-12 mt-1 d-block d-md-none" />
                                                        <div className="col-6 col-sm col-md-auto text-3 date">{moment(seg.tFDepartureData.departureDateTime).format('Do MMM YYYY ddd')}</div>
                                                        <div className="col col-md-auto text-center ms-auto order-sm-0"><span className="badge bg-success py-1 px-2 fw-normal text-0">Confirmed <i className="fas fa-check-circle" /></span></div>
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-12 col-sm-3 text-center text-md-start d-lg-flex company-info"> <span className="align-middle"><img className="img-fluid" alt src={"/images/airlinelogos/" + seg.airlineCode + ".png"} /> </span> <span className="align-middle ms-lg-2"> <span className="d-block text-2 text-dark mt-1 mt-lg-0">{seg.airline}</span> <small className="text-muted d-block">{seg.equimentType + "-" + seg.flightNumber}</small> </span> </div>
                                                        <div className="col-12 col-sm-3 text-center time-info mt-3 mt-sm-0"> <span className="text-5 text-dark">{moment(seg.tFDepartureData.departureDateTime).format('hh:mm A')}</span> <small className="text-muted d-block">Departure</small> </div>
                                                        <div className="col-12 col-sm-3 text-center time-info mt-3 mt-sm-0"> <span className="text-3 text-dark">{getTimeFromMins(seg.duration)}</span> <small className="text-muted d-block">Duration</small> </div>
                                                        <div className="col-12 col-sm-3 text-center time-info mt-3 mt-sm-0"> <span className="text-5 text-dark">{moment(seg.tFArrivalData.arrivalDateTime).format('hh:mm A')}</span> <small className="text-muted d-block">Arrival</small> </div>
                                                    </div>
                                                    <p className="mt-3 lh-base text-1">Confirmation Number: MG245C<br />
                                                        Class of Service: Economy</p>
                                                    <div className="row">
                                                        <div className="col-4 text-1 lh-base"> <strong className="text-uppercase text-2 fw-600">Airport Info</strong><br />
                                                            {seg.tFDepartureData.airportName},<br />
                                                            {seg.city}<br />
                                                        </div>
                                                        <div className="col-4 text-1 lh-base"> <strong className="text-uppercase text-2 fw-600">Flight Info</strong><br />
                                                            {seg.airline} {seg.equimentType + "-" + seg.flightNumber}<br />
                                                            Breakfast, Meal
                                                        </div>
                                                        <div className="col-4 text-1 lh-base"> <strong className="text-uppercase text-2 fw-600">Airport Info</strong><br />
                                                            {seg.tFArrivalData.airportName},<br />
                                                            {seg.tFArrivalData.city}<br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {travellers.length > 0 ?
                                            <div className="table-responsive-md" style={{ width: "100%" }}>
                                                <table className="table table-hover table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th className="text-center">Name</th>
                                                            <th className="text-center"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-center">
                                                                {travellers.map((t, index) =>
                                                                    <ul className="list-unstyled">
                                                                        <li>{t.title}. {t.firstName} {t.lastName}</li>
                                                                    </ul>
                                                                )}
                                                            </td>
                                                            <td className="text-center">
                                                                <ul className="list-unstyled">
                                                                    <li className="mb-2"><b>Base Price : </b><span className="float-end text-3 fw-500 text-dark">{baseprice} INR </span></li>
                                                                    <li className="mb-2"><b>Service Fee & Tax : </b><span className="float-end text-3 fw-500 text-dark">{tax} INR</span> </li>
                                                                    <li className="mb-2"><b>Total Price : </b><span className="float-end text-3 fw-500 text-dark">{totalPrice} INR </span> </li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div> : ""}
                                    </div>
                                    </div>
                                    <div className="text-center d-flex align-items-center justify-content-center">
                                        <button onClick={downloadPDF} className="btn btn-md btn-light-primary fw-semibold mx-2">Download Voucher</button>
                                        <button onClick={handlePrint} className="btn btn-md btn-light-primary fw-semibold mx-2">Print Voucher</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}