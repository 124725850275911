import { useEffect, useState } from "react";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import "react-datepicker/dist/react-datepicker.css";
export default function MyTravellers() {
    return (
        <div id="main-wrapper">
            <div>
                <Header></Header>
                <div className="clearfix"></div>
                <div className="py-2 gray-simple position-relative">
                    <div className="container">
                        {/* Search Form */}
                        <div className="row justify-content-center align-items-center">
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0">
                                        <li className="breadcrumb-item"><a href="#" className="text-primary">Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">About Us</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dashboard-menus border-top d-none d-lg-block">
                    <div className="container">
                        
                    </div>
                </div>
                <section className="pt-5 gray-simple position-relative">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-xl-12 col-lg-12 col-md-12 mb-4">
                                <button className="btn btn-dark fw-medium full-width d-block d-lg-none" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDashboard" aria-controls="offcanvasDashboard"><i className="fa-solid fa-gauge me-2" />Dashboard
                                    Navigation</button>
                                <div className="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabIndex={-1} id="offcanvasDashboard" aria-labelledby="offcanvasScrollingLabel">
                                    <div className="offcanvas-header gray-simple">
                                        <h5 className="offcanvas-title" id="offcanvasScrollingLabel">Offcanvas with body scrolling</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" />
                                    </div>
                                    <div className="offcanvas-body p-0">
                                        <ul className="user-Dashboard-longmenu">
                                            <li className="active"><a href="my-profile.html"><i className="fa-regular fa-id-card me-2" />My Profile</a>
                                            </li>
                                            <li><a href="my-booking.html"><i className="fa-solid fa-ticket me-2" />My Booking</a>
                                            </li>
                                            <li><a href="travelers.html"><i className="fa-solid fa-user-group me-2" />Travelers</a></li>
                                            <li><a href="payment-detail.html"><i className="fa-solid fa-wallet me-2" />Payment Details</a></li>
                                            <li><a href="my-wishlists.html"><i className="fa-solid fa-shield-heart me-2" />My Wishlist</a></li>
                                            <li><a href="settings.html"><i className="fa-solid fa-sliders me-2" />Settings</a></li>
                                            <li><a href="delete-account.html"><i className="fa-solid fa-trash-can me-2" />Delete Profile</a></li>
                                            <li><a href="login.html"><i className="fa-solid fa-power-off me-2" />Sign Out</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-start justify-content-between gx-xl-4">
                            <div className="col-xl-4 col-lg-4 col-md-12">
                                <div className="card rounded-2 me-xl-5 mb-4">
                                    <div className="card-top bg-primary position-relative">
                                        <div className="position-absolute end-0 top-0 mt-4 me-3"><a href="login.html" className="square--40 circle bg-light-dark text-light"><i className="fa-solid fa-right-from-bracket" /></a></div>
                                        <div className="py-5 px-3">
                                            <div className="crd-thumbimg text-center">
                                                <div className="p-2 d-flex align-items-center justify-content-center brd"><img src="https://placehold.co/500x500" className="img-fluid circle" width={120} alt /></div>
                                            </div>
                                            <div className="crd-capser text-center">
                                                <h5 className="mb-0 text-light fw-semibold">Adam K. Divliars</h5>
                                                <span className="text-light opacity-75 fw-medium text-md"><i className="fa-solid fa-location-dot me-2" />California, USA</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-middle px-4 py-5">
                                        <div className="crdapproval-groups">
                                            <div className="crdapproval-single d-flex align-items-center justify-content-start mb-4">
                                                <div className="crdapproval-item">
                                                    <div className="square--50 circle bg-light-success text-success"><i className="fa-solid fa-envelope-circle-check fs-5" /></div>
                                                </div>
                                                <div className="crdapproval-caps ps-2">
                                                    <p className="fw-semibold text-dark lh-2 mb-0">Verified Email</p>
                                                    <p className="text-md text-muted lh-1 mb-0">10 Aug 2022</p>
                                                </div>
                                            </div>
                                            <div className="crdapproval-single d-flex align-items-center justify-content-start mb-4">
                                                <div className="crdapproval-item">
                                                    <div className="square--50 circle bg-light-success text-success"><i className="fa-solid fa-phone-volume fs-5" /></div>
                                                </div>
                                                <div className="crdapproval-caps ps-2">
                                                    <p className="fw-semibold text-dark lh-2 mb-0">Verified Mobile Number</p>
                                                    <p className="text-md text-muted lh-1 mb-0">12 Aug 2022</p>
                                                </div>
                                            </div>
                                            <div className="crdapproval-single d-flex align-items-center justify-content-start">
                                                <div className="crdapproval-item">
                                                    <div className="square--50 circle bg-light-warning text-warning"><i className="fa-solid fa-file-invoice fs-5" /></div>
                                                </div>
                                                <div className="crdapproval-caps ps-2">
                                                    <p className="fw-semibold text-dark lh-2 mb-0">Complete Basic Info</p>
                                                    <p className="text-md text-muted lh-1 mb-0">Not Verified</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-middle mt-5 mb-4 px-4">
                                        <div className="revs-wraps mb-3">
                                            <div className="revs-wraps-flex d-flex align-items-center justify-content-between mb-1">
                                                <span className="text-dark fw-semibold text-md">Complete Your Profile</span>
                                                <span className="text-dark fw-semibold text-md">75%</span>
                                            </div>
                                            <div className="progress " role="progressbar" aria-label="Example" aria-valuenow={87} aria-valuemin={0} aria-valuemax={100} style={{ height: 7 }}>
                                                <div className="progress-bar bg-success" style={{ width: '87%' }} />
                                            </div>
                                        </div>
                                        <div className="crd-upgrades">
                                            <button className="btn btn-light-primary fw-medium full-width rounded-2" type="button"><i className="fa-solid fa-sun me-2" />Upgrade Pro</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-8 col-lg-8 col-md-12">
                                {/* Personal Information */}
                                <div className="card mb-4">
                                    <div className="card-header">
                                        <h4><i className="fa-solid fa-file-invoice me-2" />Personal Information</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="row align-items-center justify-content-start">
                                            <div className="col-xl-12 col-lg-12 col-md-12 mb-4">
                                                <div className="d-flex align-items-center">
                                                    <label className="position-relative me-4" htmlFor="uploadfile-1" title="Replace this pic">
                                                        {/* Avatar place holder */}
                                                        <span className="avatar avatar-xl">
                                                            <img id="uploadfile-1-preview" className="avatar-img rounded-circle border border-white border-3 shadow" src="https://placehold.co/500x500" alt />
                                                        </span>
                                                    </label>
                                                    {/* Upload button */}
                                                    <label className="btn btn-sm btn-light-primary px-4 fw-medium mb-0" htmlFor="uploadfile-1">Change</label>
                                                    <input id="uploadfile-1" className="form-control d-none" type="file" />
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">First Name</label>
                                                    <input type="text" className="form-control" defaultValue="Adam K" />
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">Last Name</label>
                                                    <input type="text" className="form-control" defaultValue="Divliars" />
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">Email ID</label>
                                                    <input type="text" className="form-control" defaultValue="adamkruck@gmail.com" />
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">Mobile</label>
                                                    <input type="text" className="form-control" defaultValue={9856542563} />
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">Date of Birth</label>
                                                    <input type="date" className="form-control" defaultValue="02/04/2000" />
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">Gender</label>
                                                    <input type="text" className="form-control" defaultValue="Male" />
                                                </div>
                                            </div>
                                            <div className="col-xl-12 col-lg-12 col-md-12">
                                                <div className="form-group">
                                                    <label className="form-label">About Info</label>
                                                    <textarea className="form-control ht-120" defaultValue={"Lorem ipsum dolor sit amet, nec virtute nusquam ex. Ex sed diceret constituam inciderint, accusamus imperdiet has te. Id qui liber nemore semper, modus appareat philosophia ut eam. Assum tibique singulis at mel."} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card mb-4">
                                    <div className="card-header">
                                        <h4><i className="fa-solid fa-envelope-circle-check me-2" />Update Your Email</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="row align-items-center justify-content-start">
                                            <div className="col-xl-12 col-lg-12 col-md-12">
                                                <div className="form-group">
                                                    <label className="form-label">Email Address</label>
                                                    <input type="email" className="form-control" placeholder="update your new email" />
                                                </div>
                                            </div>
                                            <div className="col-xl-12 col-lg-12 col-md-12">
                                                <div className="text-end">
                                                    <a href="#" className="btn btn-md btn-primary mb-0">Update Email</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header">
                                        <h4><i className="fa-solid fa-lock me-2" />Update Password</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="row align-items-center justify-content-start">
                                            <div className="col-xl-12 col-lg-12 col-md-12">
                                                <div className="form-group">
                                                    <label className="form-label">Old Password</label>
                                                    <input type="password" className="form-control" placeholder="*********" />
                                                </div>
                                            </div>
                                            <div className="col-xl-12 col-lg-12 col-md-12">
                                                <div className="form-group">
                                                    <label className="form-label">New Password</label>
                                                    <input type="password" className="form-control" placeholder="*********" />
                                                </div>
                                            </div>
                                            <div className="col-xl-12 col-lg-12 col-md-12">
                                                <div className="form-group">
                                                    <label className="form-label">Confirm Password</label>
                                                    <input type="password" className="form-control" placeholder="*********" />
                                                </div>
                                            </div>
                                            <div className="col-xl-12 col-lg-12 col-md-12">
                                                <div className="text-end">
                                                    <a href="#" className="btn btn-md btn-primary mb-0">Change Password</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer></Footer>
            </div>
        </div>
    )
}