import { useEffect, useState } from "react";
import Footer from "../Layout/Footer";
import AgentHeader from "../Layout/Header";
import "react-datepicker/dist/react-datepicker.css";
export default function FAQ() {
    return (
        <div id="main-wrapper">
            <div>
                <AgentHeader></AgentHeader>
                <div className="clearfix"></div>
                <div className="py-2 gray-simple position-relative">
                    <div className="container">
                        {/* Search Form */}
                        <div className="row justify-content-center align-items-center">
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0">
                                        <li className="breadcrumb-item"><a href="#" className="text-primary">Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">FAQ</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="pt-3">
                    <div className="container">
                        <div className="bg-white shadow-md rounded p-4">
                        <h4 className="text-6">Frequently Asked Questions - Green Vacations LLC</h4>
                            <div className="row mt-4 mb-2">                            
                                <div className="col-md-12">
                                    <div className="featured-box style-1">
                                        <h4>How do I search for flights or holidays on your website?</h4>
                                        <p>Navigate to our homepage and enter your departure city, destination, travel dates, and other preferences in the search bar. Click "Search" to view available options.</p>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="featured-box style-1">
                                        <h4>What types of flights do you offer?</h4>
                                        <p>We offer a wide range of flights, including economy, business, and first class. You can also choose from various airlines to find the best fit for your travel needs.</p>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="featured-box style-1">
                                        <h4>Can I book a complete holiday package on your website?</h4>
                                        <p>Absolutely! In addition to flights, we provide comprehensive holiday packages that may include accommodations, transportation, and activities. Simply select the "Holidays" tab on our website to explore these options.</p>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="featured-box style-1">
                                        <h4>Are the prices displayed inclusive of all fees and taxes?</h4>
                                        <p>Yes, the prices you see on our website include all applicable fees and taxes. There are no hidden charges, and the displayed amount is the total cost you will pay.</p>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="featured-box style-1">
                                        <h4>How can I make a payment for my booking?</h4>
                                        <p>We accept various payment methods, including credit/debit cards and other secure online payment options. Follow the prompts during the checkout process to complete your payment.</p>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="featured-box style-1">
                                        <h4>What is your cancellation policy?</h4>
                                        <p>Cancellation policies vary depending on the airline or holiday package. You can find detailed information during the booking process, and we recommend reviewing the terms and conditions before finalizing your reservation.</p>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="featured-box style-1">
                                        <h4>How will I get my money back after a cancellation?</h4>
                                        <p>We will credit the money back to the same account you used while making the booking. For example, if you used your credit card, we will make an appropriate charge reversal. If you used your debit card, we will credit the money back to the debit card.</p>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="featured-box style-1">
                                        <h4>How long does it take to process a refund?</h4>
                                        <p>We usually process the refund within 4 working days of cancellation. However, it may take slightly longer to reflect in your account statement depending upon your bank. We’ve noticed that it takes about 14 working days for most refunds to hit their respective accounts.</p>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="featured-box style-1">
                                        <h4>I still haven’t received my refund. Help!</h4>
                                        <p>Please email us greenvacations@gmail.com or contact us @ +91 94918 07865 with booking details</p>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="featured-box style-1">
                                        <h4>What if I encounter issues during my trip?</h4>
                                        <p>Our customer support team is available 24/7 to assist you. Contact us through the provided helpline or email, and we will promptly address any concerns or issues you may have during your journey.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer></Footer>
            </div>
        </div>
    )
}