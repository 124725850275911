import { useEffect, useState } from "react";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import APIConfig from "../Configuration/APIConfig";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import PageLoader from "../Layout/PageLoader";
import moment from "moment";

export default function VisaSubmission() {
    const { state } = useLocation();
    const [visaRequest, setVisaRequest] = useState({})
    const [loading, setLoading] = useState(true);
    const params = useParams();
    useEffect(() => {
        console.log(state)
        if (state != null) {
            setVisaRequest(state.visa)
            setLoading(false);
        }
    }, []);
    return (
        <div id="main-wrapper">
            {loading === true ? <PageLoader></PageLoader> :
                <div>
                    <Header></Header>
                    <div className="clearfix"></div>
                    <div className="py-2 bg-primary position-relative">
                    </div>
                    <section className="gray-simple">
                        <div className="container">
                            <div className="row my-2">
                                {/* Steps Progress bar end */}
                                <div className="col-lg-12 text-center mt-0">
                                    <h3 className="text-6">Visa Application Successfully Submitted</h3>
                                    <p className="lead">We are processing the same and you will be notified via email.</p>
                                </div>
                                <div className="col-md-8 col-lg-6 col-xl-5 mx-auto">
                                    <div className="bg-white shadow-sm rounded p-4 p-lg-5 mb-4">
                                        <div className="row">
                                            <div className="col-sm text-muted">Reference No</div>
                                            <div className="col-sm text-sm-end fw-600">{visaRequest.visaReferenceNo}</div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="col-sm text-muted">Visa</div>
                                            <div className="col-sm text-sm-end fw-600">{visaRequest.visaName}</div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="col-sm text-muted">Country</div>
                                            <div className="col-sm text-sm-end fw-600">{visaRequest.countryName}</div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="col-sm text-muted">Transaction Status</div>
                                            <div className="col-sm text-sm-end fw-600 text-success">Success</div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="col-sm text-muted">Visa Status</div>
                                            <div className="col-sm text-sm-end fw-600 text-success">Submitted</div>
                                        </div>
                                        <hr />
                                    </div>
                                    <div className="text-center">
                                        <p className="mt-4 mb-0"><Link to={"/searchvisas"} className="btn btn-primary">Back to search</Link></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer></Footer>
                </div>
            }
        </div>
    )
}