import { useEffect, useState } from "react";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import "react-datepicker/dist/react-datepicker.css";
import UserTab from "./UserTab";
import UserMobileTab from "./UserMobileTab";
import APIConfig from "../Configuration/APIConfig";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
export default function MyBookings() {
    const navigate = useNavigate();
    const [flights, setFlights] = useState([]);
    const [segments, setSegments] = useState([]);
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken")
        }
    }
    const GetUserFlights = () => {
        axios
            .post(APIConfig.APIACTIVATEURL + APIConfig.GETUSERFLIGHTS + "?UserId=" + localStorage.getItem('userId'), { ...headerconfig })
            .then((response) => {
                setFlights(response.data.data.data);
                if(response.data.data.data !=null)
                {
                    setSegments(response.data.data.data.segments)
                }
            });
    };
    useEffect(() => {
        if (localStorage.getItem('userId') === null) {
            localStorage.clear();
            navigate("/");
        }
        else {
            GetUserFlights();
        }
    }, [])
    return (
        <div id="main-wrapper">
            <div>
                <Header></Header>
                <div className="clearfix"></div>
                <div className="py-2 gray-simple position-relative">
                    <div className="container">
                        {/* Search Form */}
                        <div className="row justify-content-center align-items-center">
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0">
                                        <li className="breadcrumb-item"><a href="#" className="text-primary">Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Flight Bookings</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dashboard-menus border-top d-none d-lg-block">
                    <div className="container">
                        <UserTab></UserTab>
                    </div>
                </div>
                <section className="pt-5 gray-simple position-relative">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-xl-12 col-lg-12 col-md-12 mb-4">
                                <button className="btn btn-dark fw-medium full-width d-block d-lg-none" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDashboard" aria-controls="offcanvasDashboard"><i className="fa-solid fa-gauge me-2" />Dashboard
                                    Navigation</button>
                                <UserMobileTab></UserMobileTab>
                            </div>
                        </div>
                        <div className="row align-items-start justify-content-between gx-xl-4">
                            <div className="col-xl-12 col-lg-8 col-md-12">
                                {/* Personal Information */}
                                <div className="card">
                                    <div className="card-header">
                                        <h4><i className="fa-solid fa-ticket me-2" />My Bookings</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="row align-items-center justify-content-start">
                                            <div className="col-xl-12 col-lg-12 col-md-12">
                                                {flights.length>0 && flights.map((f,index)=>
                                                <div className="card border br-dashed mb-4">
                                                    {/* Card header */}
                                                    <div className="card-header nds-block border-bottom flex-column flex-md-row justify-content-between align-items-center">
                                                        {/* Icon and Title */}
                                                        <div className="d-flex align-items-center">
                                                            <div className="square--50 circle bg-light-purple text-purple flex-shrink-0"><i className="fa-solid fa-plane" /></div>
                                                            {/* Title */}
                                                            <div className="ms-2">
                                                                <h6 className="card-title text-dark fs-5 mb-1">{f.locationFrom} To {f.locationTo}</h6>
                                                                <ul className="nav nav-divider small">
                                                                    <li className="nav-item text-muted">Booking ID: {f.bookingNo}</li>
                                                                    <li className="nav-item ms-2"><span className="label bg-light-success text-success">{f.journeyType}</span></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        {/* Button */}
                                                        <div className="mt-2 mt-md-0">
                                                            <Link to={"/mybookingdetails/"+f.flightBookingId} className="btn btn-md btn-light-seegreen fw-medium mb-0">View Booking</Link>
                                                        </div>
                                                    </div>
                                                    {/* Card body */}
                                                    <div className="card-body">
                                                        <div className="row g-3">
                                                            <div className="col-sm-6 col-md-4">
                                                                <span>Departure</span>
                                                                <h6 className="mb-0">{moment(f.departureDate).format('DD MMM YYYY')}</h6>
                                                            </div>
                                                            {f.journeyType==="ROUNDTRIP"?
                                                            <div className="col-sm-6 col-md-4">
                                                                <span>Return</span>
                                                                <h6 className="mb-0">{moment(f.returnDate).format('DD MMM YYYY')}</h6>
                                                            </div>:""}
                                                            <div className="col-md-4">
                                                                <span>Traveler</span>
                                                                <h6 className="mb-0">{f.travellerName}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>                                                
                                                )}                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer></Footer>
            </div>
        </div>
    )
}