import { useEffect, useState } from "react";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import APIConfig from "../Configuration/APIConfig";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import PageLoader from "../Layout/PageLoader";
import moment from "moment";
const defaultMaleImage = "/assets/images/default_male_avatar.jpg";
const defaultPFImage = "/assets/images/passport.jpg";
const defaultPBImage = "/assets/images/passport.jpg";
const initialValues = {
    transactionId: "00000000-0000-0000-0000-000000000000",
    amount: 0,
    transactionType: "CREDIT",
    bookingType: "VISA",
    status: "",
    referenceNo: "",
    paymentGatewayNo: "",
    createdDate: new Date(),
    referenceId: "00000000-0000-0000-0000-000000000000"
};
export default function VisaApplicant() {
    const params = useParams();
    const navigate = useNavigate();
    const [applicants, setApplicants] = useState([]);
    const [errors, setErrors] = useState({});
    const [visa, setVisa] = useState({});
    const [proceed, setProceed] = useState(0);
    const [loading, setLoading] = useState(true);
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken"),
        }
    }
    const handleInputChange = (index, e) => {
        const values = [...applicants];
        const updatedValue = e.target.name;
        values[index][updatedValue] = e.target.value;
        setApplicants(values);
    };
    const toInputUppercase = e => {
        e.target.value = ("" + e.target.value).toUpperCase();
    };
    const GetVisaDetails = () => {
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETVISAREQUESTBYID + "/" + params.visaRequestId, { ...headerconfig })
            .then((response) => {
                setVisa(response.data.data);
                setLoading(false)
            });
    };
    const showProfilePreview = (index, e) => {
        const dvalues = [...applicants];
        if (e.target.files && e.target.files[0]) {
            let profileFile = e.target.files[0];
            const reader = new FileReader();
            reader.onload = x => {
                dvalues[index]['profileFile'] = profileFile;
                dvalues[index]['profileSrc'] = x.target.result;
                setApplicants(dvalues)
            }
            reader.readAsDataURL(profileFile)
        }
    }
    const showPassportFrontPreview = (index, e) => {
        if (e.target.files && e.target.files[0]) {
            const values = [...applicants];
            let passportFrontFile = e.target.files[0];
            const reader = new FileReader();
            reader.onload = x => {
                values[index]['passportFrontFile'] = passportFrontFile;
                values[index]['passportFrontSrc'] = x.target.result;
                setApplicants(values)
            }
            reader.readAsDataURL(passportFrontFile)
        }
    }
    const showPassportBackPreview = (index, e) => {
        if (e.target.files && e.target.files[0]) {
            const values = [...applicants];
            let passportBackFile = e.target.files[0];
            const reader = new FileReader();
            reader.onload = x => {
                values[index]['passportBackFile'] = passportBackFile;
                values[index]['passportBackSrc'] = x.target.result;
                setApplicants(values)
            }
            reader.readAsDataURL(passportBackFile)
        }
    }
    const validate = (index, adata) => {
        console.log(adata)
        let temp = {};
        temp.firstName = adata.firstName === "" ? false : true;
        temp.lastName = adata.lastName === "" ? false : true;
        temp.passportNumber = adata.passportNumber === "" ? false : true;
        temp.profileSrc = adata.profileSrc === defaultMaleImage ? false : true;
        temp.passportFrontSrc = adata.passportFrontSrc === defaultPFImage ? false : true;
        temp.passportBackSrc = adata.passportBackSrc === defaultPBImage ? false : true;
        temp.gender = adata.gender === "0" ? false : true;
        var newDate = new Date();
        var futureDate = moment(new Date(newDate.setMonth(newDate.getMonth() + 6))).format('YYYY-MM-DD');
        if (adata.passportExpireDate < futureDate) {
            temp.passportExpireDate = false;
        }
        else {
            temp.passportExpireDate = true;
        }
        setErrors(temp);
        return Object.values(temp).every((x) => x === true);
    };
    function checkValidation() {
        applicants.map(function (adata, index) {
            if (validate(index, adata)) {
                return true;
            }
            else {
                return false;
            }
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        if (window.confirm('Are you sure to submit the data?')) {
            if (checkValidation) {
                displayRazorpay();
            }
        }
    };
    async function InsertApplicants() {
        applicants.map(function (adata, index) {
            const formData = new FormData()
            formData.append("visaApplicantId", adata.visaApplicantId)
            formData.append("visaRequestId", params.visaRequestId)
            formData.append("firstName", adata.firstName)
            formData.append("lastName", adata.lastName)
            formData.append("title", adata.title)
            formData.append("gender", adata.gender)
            formData.append("visaApplicantStatus", "NEW")
            formData.append("dateOfBirth", moment(adata.dateOfBirth).format('YYYY-MM-DD'))
            formData.append("passportIssueDate", moment(adata.passportIssueDate).format('YYYY-MM-DD'))
            formData.append("passportExpireDate", moment(adata.passportExpireDate).format('YYYY-MM-DD'))
            formData.append("email", "taanu51@gmail.com")
            formData.append("mobile", "9985340876")
            formData.append("passportNumber", adata.passportNumber)
            formData.append("travelerType", adata.travelerType)
            formData.append("nationality", adata.nationality)
            formData.append("passportCountry", adata.passportCountry)
            formData.append("profileFile", adata.profileFile)
            formData.append("passportFrontFile", adata.passportFrontFile)
            formData.append("passportBackFile", adata.passportBackFile)
            formData.append("comments", adata.comments)
            axios.post(APIConfig.APIACTIVATEURL + APIConfig.CREATEVISAAPPLICANT, formData, { ...headerconfig })
                .then((res) => {
                })
        });
        return true;
    }
    const applyErrorClass = (field) =>
        field in errors && errors[field] === false ? " form-control-danger" : "";
    useEffect(() => {
        GetVisaDetails();
        setProceed(params.noOfApplicants)
        if (applicants.length < params.noOfApplicants) {
            const values = [...applicants];
            for (var i = 0; i < params.noOfApplicants; i++) {
                values.push({
                    visaApplicantId: "00000000-0000-0000-0000-000000000000",
                    visaRequestId: "00000000-0000-0000-0000-000000000000",
                    firstName: "",
                    lastName: "",
                    title: "Mr",
                    gender: "0",
                    dateOfBirth: moment(new Date()).format('DD-MM-YYYY'),
                    email: "taanu51@gmail.com",
                    nationality: "Indian",
                    mobile: "9985340876",
                    passportNumber: "",
                    passportCountry: "India",
                    passportIssueDate: moment(new Date()).format('DD-MM-YYYY'),
                    passportExpireDate: moment(new Date()).format('DD-MM-YYYY'),
                    travelerType: "ADULT",
                    profileSrc: defaultMaleImage,
                    profileFile: null,
                    passportFrontSrc: defaultPFImage,
                    passportFrontFile: null,
                    passportBackSrc: defaultPBImage,
                    passportBackFile: null
                });
                setApplicants(values);;
            }
        }
    }, []);
    /* Payment Gateway */
    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }
    async function displayRazorpay() {
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }
        // creating a new order
        const result = await axios.post(
            APIConfig.APIACTIVATEURL +
            APIConfig.PAYMENTREQUESTORDER + "?Amount=" + visa.totalPrice
        );

        if (!result) {
            alert("Server error. Are you online?");
            return;
        }
        if (result.data.response.succeeded) {
            const { amount, id: order_id, currency } = result.data.response.data;

            const options = {
                key: APIConfig.RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
                amount: amount,
                currency: currency,
                name: APIConfig.COMPANYNAME,
                description: "Visa Application Fee",
                order_id: order_id,
                handler: async function (response) {
                    const data = {
                        orderCreationId: order_id,
                        razorpayPaymentId: response.razorpay_payment_id,
                        razorpayOrderId: response.razorpay_order_id,
                        razorpaySignature: response.razorpay_signature,
                    };
                    const formData = new FormData()
                    formData.append("transactionId", "00000000-0000-0000-0000-000000000000")
                    formData.append("amount", visa.totalPrice)
                    formData.append("transactionType", "CREDIT")
                    formData.append("referenceId", params.visaRequestId)
                    formData.append("bookingType", "VISA")
                    formData.append("status", "SUCCESS")
                    formData.append("paymentGatewayNo", response.razorpay_payment_id)
                    await InsertApplicants();
                    InsertTransaction(formData);
                },
                prefill: {
                    name: "",
                    email: "greenvacations@gmail.com",
                    contact: "9123456789",
                },
                notes: {
                    address: "Hyderabad",
                },
                theme: {
                    color: "#61dafb",
                },
            };

            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
        }
        else {
            alert("Payment failed. Please try again");
        }
    }
    const InsertTransaction = (data) => {
        axios.post(APIConfig.APIACTIVATEURL + APIConfig.CREATETRANSACTION, data, { ...headerconfig })
            .then((res) => {
                if (res.data.statusCode === 200) {
                    navigate('/visasubmission/', { state: { visa } });
                }
            });
    };
    return (
        <div id="main-wrapper">
            {loading === true ? <PageLoader></PageLoader> :
                <div>
                    <Header></Header>
                    <div className="clearfix"></div>
                    <div className="py-2 bg-primary position-relative">
                    </div>
                    <section className="gray-simple">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="bg-white shadow-md rounded p-3">
                                        <h3 className="text-5 mb-2">Applicant Details</h3>
                                        <hr className="mx-n3" />
                                        <div class="alert alert-info shadow-md"> <i class="fas fa-check-circle"></i> <strong class="fw-500">Note : </strong> Please fill complete information as per your passport. </div>
                                    </div>
                                    {applicants.length > 0 && applicants.map((applicant, index) =>
                                        <div className="accordion" id="default-accordion-example">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingOne">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse" + index} aria-expanded="true" aria-controls={"collapse" + index}>
                                                        Applicant {parseInt(index + 1)}
                                                    </button>
                                                </h2>
                                                <div id={"collapse" + index} className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#default-accordion-example">
                                                    <div className="accordion-body">
                                                        <div className="row g-3 mb-4">
                                                            <div className="col-sm-4 col-12">
                                                                <div className="picture-container">
                                                                    <div className="picture">
                                                                        <label for="firstnamefloatingInput">Photo (2 inch x 2 inch)</label>
                                                                        <img src={applicant.profileSrc} className="picture-src" width="200px" height="200px" controls />
                                                                    </div>
                                                                    <input id="image-uploader" name="profileSrc" className={"form-control-file" + applyErrorClass('profileSrc')} type="file" onChange={e => showProfilePreview(index, e)} />
                                                                    {errors.profileSrc === false ? (<div className="validationerror">Please upload image </div>) : ('')}
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-4 col-12">
                                                                <div className="picture-container">
                                                                    <div className="picture">
                                                                        <label for="firstnamefloatingInput">Passport Front Page</label>
                                                                        <img src={applicant.passportFrontSrc} className="picture-src" width="200px" height="200px" controls />
                                                                    </div>
                                                                    <input id="image-uploader" name="passportFrontSrc" className={"form-control-file" + applyErrorClass('passportFrontSrc')} type="file" onChange={e => showPassportFrontPreview(index, e)} />
                                                                    {errors.passportFrontSrc === false ? (<div className="validationerror">Please upload image </div>) : ('')}
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-4 col-12">
                                                                <div className="picture-container">
                                                                    <div className="picture">
                                                                        <label for="firstnamefloatingInput">Passport Back Page</label>
                                                                        <img src={applicant.passportBackSrc} className="picture-src" width="200px" height="200px" controls />
                                                                    </div>
                                                                    <input id="image-uploader" name="passportBackSrc" className={"form-control-file" + applyErrorClass('passportBackSrc')} type="file" onChange={e => showPassportBackPreview(index, e)} />
                                                                    {errors.passportBackSrc === false ? (<div className="validationerror">Please upload image </div>) : ('')}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <p className="text-danger">Please fill the application as per your passport.</p>
                                                        <div className="row g-3">
                                                            <div className="col-sm-4">
                                                                <div className="form-floating">
                                                                    <input type="text" onInput={toInputUppercase} value={applicant.firstName} name="firstName" onChange={e => handleInputChange(index, e)} className={"form-control" + applyErrorClass('firstName')} placeholder="First Name" />
                                                                    <label for="firstnamefloatingInput">First Name</label>
                                                                    {errors.firstName === false ? (<div className="validationerror">Please enter firstName </div>) : ('')}
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <div className="form-floating">
                                                                    <input type="text" onInput={toInputUppercase} value={applicant.lastName} name="lastName" onChange={e => handleInputChange(index, e)} className={"form-control" + applyErrorClass('lastName')} placeholder="Last Name" />
                                                                    <label for="lastNamefloatingInput">Last Name</label>
                                                                    {errors.lastName === false ? (<div className="validationerror">Please enter lastName </div>) : ('')}
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <div className="form-floating">
                                                                    <input type="text" onInput={toInputUppercase} value={applicant.passportNumber} maxLength={12} min={8} name="passportNumber" onChange={e => handleInputChange(index, e)} className={"form-control" + applyErrorClass('passportNumber')} placeholder="Passport Number" />
                                                                    <label for="passportNumberfloatingInput">Passport Number</label>
                                                                    {errors.passportNumber === false ? (<div className="validationerror">Please enter passportNumber </div>) : ('')}
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <div className="form-floating">
                                                                    <input type="date" value={applicant.dateOfBirth} name="dateOfBirth" onChange={e => handleInputChange(index, e)} className={"form-control" + applyErrorClass('dateOfBirth')} placeholder="Date Of Birth" />
                                                                    <label for="dateOfBirthfloatingInput">Date Of Birth</label>
                                                                    {errors.dateOfBirth === false ? (<div className="validationerror">Please enter dateOfBirth </div>) : ('')}
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <div className="form-floating">
                                                                    <input type="date" value={applicant.passportIssueDate} name="passportIssueDate" onChange={e => handleInputChange(index, e)} className={"form-control" + applyErrorClass('passportIssueDate')} placeholder="Passport Issue Date" />
                                                                    <label for="passportIssueDate">Passport Issue Date</label>
                                                                    {errors.passportIssueDate === false ? (<div className="validationerror">Please enter Passport Issue Date </div>) : ('')}
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <div className="form-floating">
                                                                    <input type="date" value={applicant.passportExpireDate} name="passportExpireDate" onChange={e => handleInputChange(index, e)} className={"form-control" + applyErrorClass('passportExpireDate')} placeholder="Passport Expiry Date" />
                                                                    <label for="passportExpireDate">Passport Expiry Date</label>
                                                                    {errors.passportExpireDate === false ? (<div className="validationerror">Passport must have an 'expiry date' at least 6 months</div>) : ('')}
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <div className="form-floating">
                                                                    <select className="form-select" value={applicant.gender} name="gender" onChange={e => handleInputChange(index, e)}>
                                                                        <option value="0">Gender</option>
                                                                        <option value="Male">Male</option>
                                                                        <option value="Female">Female</option>
                                                                    </select>
                                                                    {errors.gender === false ? (<div className="validationerror">Please select gender </div>) : ('')}
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <div className="form-floating">
                                                                    <select className="form-select" value={applicant.nationality} name="nationality" onChange={e => handleInputChange(index, e)}>
                                                                        <option value="0">Indian</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <div className="form-floating">
                                                                    <select className="form-select" value={applicant.passportCountry} name="passportCountry" onChange={e => handleInputChange(index, e)}>
                                                                        <option value="0">India</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <aside className="col-lg-4 mt-4 mt-lg-0">
                                    <div className="sticky-top">
                                        <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                                            <div className="bg-white shadow-md rounded p-3">
                                                <h3 className="text-5 mb-3">Visa Details</h3>
                                                <hr className="mx-n3" />
                                                <ul className="list-unstyled">
                                                    <li className="mb-2"><b>Visa</b> <span className="float-end text-dark">{visa.visaName}</span></li>
                                                    <li className="mb-2"><b>Country</b> <span className="float-end text-dark">{visa.countryName}</span></li>
                                                    <li className="mb-2"><b>No. Of Applicants</b> <span className="float-end text-dark">{visa.noOfApplicants}</span></li>
                                                    <li className="mb-2"><b>Price Per Applicant</b> <span className="float-end text-dark">{visa.pricePerApplicant} INR</span></li>
                                                    <li className="mb-2"><b>Total Amount</b> <span className="float-end text-dark">{visa.totalPrice} INR</span></li>
                                                </ul>
                                                <h3 className="text-4 mb-3 mt-4"></h3>
                                                <div className="d-grid">
                                                    <button className="btn btn-primary" type="submit">Proceed To Payment</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </aside>
                                {/* Side Panel */}
                            </div>
                        </div>
                    </section>
                    <Footer></Footer>
                </div>
            }
        </div>
    )
}