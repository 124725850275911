import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
export default function UserMobileTab(props) {
    return (
        <div className="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabIndex={-1} id="offcanvasDashboard" aria-labelledby="offcanvasScrollingLabel">
            <div className="offcanvas-header gray-simple">
                <h5 className="offcanvas-title" id="offcanvasScrollingLabel"></h5>
                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" />
            </div>
            <div className="offcanvas-body p-0">
                <ul className="user-Dashboard-longmenu">
                <li className="active"><Link to={"/profile"}><i className="fa-regular fa-id-card me-2" />My Profile</Link></li>
                    <li><Link to={"/mybookings"}><i className="fa-solid fa-ticket me-2" />My Bookings</Link></li>
                    <li><Link to={"/logout"}><i className="fa-solid fa-power-off me-2" />Sign Out</Link></li>
                </ul>
            </div>
        </div>
    );
}