import { Link } from "react-router-dom";

export default function HomeBanner(props) {
    return (
        <div className="col-xl-9 col-lg-10 col-md-12 col-sm-12">
            <div className="position-relative text-center mb-0">
            {/* <h1>Explore The World <span class="position-relative z-4">Around</span> You</h1>
            <p class="fs-5 fw-light">Take a little break from the work strss of everyday. Discover plan trip and explore beautiful destinations.</p> */}
            </div>
        </div>
    )
}