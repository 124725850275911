import { useEffect, useRef, useState } from "react";
import Footer from "../Layout/Footer";
import "react-datepicker/dist/react-datepicker.css";
import Agenttab from "./UserTab";
import AgentMobileTab from "./UserMobileTab";
import APIConfig from "../Configuration/APIConfig";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useReactToPrint } from 'react-to-print';
import { DownloadFlightVoucher } from "../Flights/DownloadFlightVoucher";
import Header from "../Layout/Header";
export default function FlightBookingDetails() {
    const params = useParams();
    const navigate = useNavigate();
    const [flightBooking, setFlightBooking] = useState('');
    const [travelers, setTravelers] = useState([]);
    const [segments, setSegments] = useState([]);
    const contentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => contentRef.current,
    });
    const downloadPDF = () => {
        const input = contentRef.current;
        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF();
            const imgWidth = 210;
            const pageHeight = 297;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;
            let position = 0;

            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }

            pdf.save('flightbooking.pdf');
        });
    };
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken")
        }
    }
    function getTimeFromMins(mins) {
        var h = mins / 60 | 0, m = mins % 60 | 0;
        var durationformat = h + "h " + m + "m";
        return durationformat;
    }
    const GetFlightBookingDetails = () => {
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETFLIGHTBOOKINGBYID + "?Id=" + params.flightBookingId, { ...headerconfig })
            .then((response) => {
                setFlightBooking(response.data.response.data);
                setTravelers(response.data.response.data.travelers);
                setSegments(response.data.response.data.tfSegments);
            });
    };
    useEffect(() => {
        if (localStorage.getItem('userId') === null) {
            localStorage.clear();
            navigate("/");
        }
        else {
            GetFlightBookingDetails();
        }
    }, [])
    return (
        <div id="main-wrapper">
            <div>
                <Header></Header>
                <div className="clearfix"></div>                
                <div className="dashboard-menus border-top d-none d-lg-block" style={{backgroundColor:'cadetblue'}}>
                    <div className="container">
                        <Agenttab></Agenttab>
                    </div>
                </div>
                <div className="py-2 gray-simple position-relative">
                    <div className="container">
                        {/* Search Form */}
                        <div className="row justify-content-center align-items-center">
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0">
                                        <li className="breadcrumb-item"><a href="#" className="text-primary">Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Flight Booking Details</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="pt-5 gray-simple position-relative">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-xl-12 col-lg-12 col-md-12 mb-4">
                                <button className="btn btn-dark fw-medium full-width d-block d-lg-none" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDashboard" aria-controls="offcanvasDashboard"><i className="fa-solid fa-gauge me-2" />Dashboard
                                    Navigation</button>
                                <AgentMobileTab></AgentMobileTab>
                            </div>
                        </div>
                        <div className="row align-items-start justify-content-between gx-xl-4">
                            <div className="col-xl-12 col-lg-8 col-md-12">
                                {/* Personal Information */}
                                <div className="card">
                                <div ref={contentRef}>
                                    <div className="text-center d-flex align-items-center justify-content-center">
                                        <img src="/assets/img/logo-light.png" width={200} height={100}></img>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-center flex-column text-center mb-5 mt-2">
                                        <h3 className="mb-0">Booking Confirmation!</h3>
                                    </div>
                                    <p style={{ paddingLeft: "20px" }}>Dear Traveler, Following are the flight booking details</p>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row mb-3">
                                                <div className="col-lg-3">
                                                    <label className="form-label"><b>Journey Type</b> : {flightBooking.journeyType}</label>
                                                </div>
                                                <div className="col-lg-3">
                                                    <label className="form-label"><b>Booking No</b> : {flightBooking.bookingNo}</label>
                                                </div>
                                                <div className="col-lg-3">
                                                    <label className="form-label"><b>PNR</b> : {flightBooking.pnr}</label>
                                                </div>
                                                <div className="col-lg-3">
                                                    <label className="form-label"><b>Booking Status</b> : {flightBooking.bookingStatus}</label>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-lg-3">
                                                    <label className="form-label"><b>Location From</b> : {flightBooking.locationFrom}</label>
                                                </div>
                                                <div className="col-lg-3">
                                                    <label className="form-label"><b>Location To</b> : {flightBooking.locationTo}</label>
                                                </div>
                                                <div className="col-lg-3">
                                                    <label className="form-label"><b>Departure Date </b> : {moment(flightBooking.departureDate).format('Do MMM yyyy')}</label>
                                                </div>
                                                {flightBooking.journeyType=="ROUNDTRIP"?
                                                <div className="col-lg-3">
                                                    <label className="form-label"><b>Return Date </b> : {moment(flightBooking.returnDate).format('Do MMM yyyy')}</label>
                                                </div>:""}
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-lg-3">
                                                    <label className="form-label"><b>Base Price</b> : {flightBooking.basePrice} INR</label>
                                                </div>                                                
                                                <div className="col-lg-3">
                                                    <label className="form-label"><b>Airline Tax & Fee</b> : {flightBooking.tax} INR</label>
                                                </div>
                                                <div className="col-lg-3">
                                                    <label className="form-label"><b>GST({flightBooking.gst}%)</b> : {flightBooking.gstAmount} INR</label>
                                                </div>
                                                <div className="col-lg-3">
                                                    <label className="form-label"><b>Total Price</b> : {flightBooking.totalPrice} INR</label>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                            <div className="col-lg-3">
                                                <label className="form-label"><b>Booking Date </b> : {moment(flightBooking.bookingDate).format('Do MMM yyyy hh:mm a')}</label>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header">
                                            <h3>Itinerary</h3>
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive table-card">
                                                <table className="table align-middle" id="customerTable">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th>Departure</th>
                                                            <th></th>
                                                            <th>Arrival</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="list form-check-all">
                                                        {segments.length > 0 && segments.map((v, index) =>
                                                            <tr key={index}>
                                                                <td className="id">{moment(v.tFDepartureData.departureDateTime).format('DD MMM YYYY hh:mm a')}<br />{v.tFDepartureData.airportName}, {v.tFDepartureData.airportCode} <br />{v.tFDepartureData.city}, {v.tFDepartureData.country}</td>
                                                                <td className="id">{v.airline},({v.airlineCode}) <br /> {v.equimentType} - {v.flightNumber}<br />Duration : {getTimeFromMins(v.duration)}</td>
                                                                <td className="id">{moment(v.tFDepartureData.arrivalDateTime).format('DD MMM YYYY hh:mm a')}<br />{v.tFArrivalData.airportName}, {v.tFArrivalData.airportCode} <br />{v.tFArrivalData.city}, {v.tFArrivalData.country}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header">
                                            <h3>Travelers</h3>
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive table-card">
                                                <table className="table align-middle" id="customerTable">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th>Id</th>
                                                            <th>Name</th>
                                                            <th>Type</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="list form-check-all">
                                                        {travelers.length > 0 && travelers.map((v, index) =>
                                                            <tr key={v.travelerId}>
                                                                <td>{index + 1}</td>
                                                                <td className="id">{v.title} {v.firstName} {v.lastName}</td>
                                                                <td className="id">{v.travelerType}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="text-center d-flex align-items-center justify-content-center">
                                        <button onClick={downloadPDF} className="btn btn-md btn-light-primary fw-semibold mx-2">Download Voucher</button>
                                        <button onClick={handlePrint} className="btn btn-md btn-light-primary fw-semibold mx-2">Print Voucher</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer></Footer>
                {/* <div style={{ visibility: "hidden" }}>
                    <DownloadFlightVoucher ref={contentRef} flightBookingId={params.flightBookingId}></DownloadFlightVoucher>
                </div> */}
            </div>
        </div>
    )
}